import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import tw from 'twin.macro';
import Logo from '../components/logo';
import ContentWidth from '../components/contentWidth';
import Modal from 'react-modal';
import Iframe from 'react-iframe';

type HomeNode = {
  id: string;
  top: {
    image: { childImageSharp: { fluid: FluidObject } };
  };
  about: {
    description: string[];
    link: {
      title: string;
    };
    image: { childImageSharp: { fluid: FluidObject } };
  };
  works: {
    link: {
      title: string;
    };
    list: {
      title: string[];
      iframe: {
        url: string;
        image: { childImageSharp: { fluid: FluidObject } };
      };
      description: string[];
      image: { childImageSharp: { fluid: FluidObject } };
    }[];
  };
};

type Props = {
  location: Location;
  data: {
    home: {
      edges: [{ node: HomeNode }];
    };
  };
};

function Home({ location, data: { home } }: Props) {
  const [activeModal, setActiveModal] = React.useState('');

  React.useEffect(() => {
    Modal.setAppElement('body');
  });

  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.home.path}
    >
      <SEO
        image={seoData.home.image?.[defaultLangKey]}
        title={seoData.home.title?.[defaultLangKey]}
        description={seoData.home?.description?.[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      {home.edges.map(({ node }: { node: HomeNode }) => (
        <div key={node.id}>
          <section
            css={tw`flex flex-col justify-center -mt-12 md:-mt-24 relative`}
            style={{ height: '100vh' }}
          >
            <BackgroundImage
              Tag="div"
              fluid={node.top.image.childImageSharp.fluid}
              fadeIn={true}
              css={tw`w-full h-full flex-none bg-cover text-center bg-gray-200 mx-auto mb-16`}
              style={{ position: 'absolute', zIndex: 0 }}
            />
            <div
              css={tw`w-full h-full flex-none bg-cover text-center bg-black bg-opacity-25 mx-auto mb-16`}
              style={{ position: 'absolute', zIndex: 0 }}
            />
            <div css={tw`flex flex-col items-center`}>
              <div css={tw`w-3/4 md:w-full mx-auto`}>
                <Logo color={'#fff'} />
              </div>
            </div>
          </section>
          <ContentWidth>
            <section id="about">
              <div css={tw`my-24 md:my-32 md:mb-40 flex flex-col`}>
                <h2 css={tw`mb-12 text-title font-bold text-3xl md:text-4xl`}>
                  About
                </h2>
                <div
                  css={tw`flex flex-col items-center justify-center leading-none`}
                >
                  <div css={tw`text-3xl md:text-5xl leading-normal font-bold`}>
                    <p>会社概要</p>
                  </div>
                  <div
                    css={tw`md:text-center text-sm md:text-xl font-normal leading-loose my-12 md:my-24`}
                  >
                    {node.about.description.map((line) => {
                      return <p key={line}>{line}</p>;
                    })}
                  </div>
                </div>
              </div>
            </section>
            <section id="works">
              <div css={tw`flex flex-col`}>
                <h2
                  css={tw`mb-8 md:mb-12 text-title font-bold text-3xl md:text-4xl`}
                >
                  Works
                </h2>
              </div>
              <div>
                {node.works.list.map((service, index) => {
                  const isEven = (index + 1) % 2 === 0;
                  return (
                    <div
                      key={`service-${index}`}
                      css={
                        isEven
                          ? tw`flex flex-col md:flex-row md:flex-row-reverse mb-12 md:mb-24 last:mb-0 bg-black bg-opacity-10 md:bg-opacity-100 md:bg-transparent`
                          : tw`flex flex-col md:flex-row mb-12 md:mb-24 last:mb-0 bg-black bg-opacity-10 md:bg-opacity-100 md:bg-transparent`
                      }
                    >
                      <div
                        css={tw`w-full md:w-2/5 hover:opacity-50 cursor-pointer`}
                        onClick={() => setActiveModal(`modal-${index}`)}
                      >
                        <Image fluid={service.image.childImageSharp.fluid} />
                      </div>

                      <Modal
                        closeTimeoutMS={200}
                        isOpen={activeModal === `modal-${index}`}
                        contentLabel={`modal-${index}`}
                        onRequestClose={() => setActiveModal('')}
                        className={!!service.iframe.url ? 'iframe' : 'image'}
                      >
                        {!!service.iframe.url ? (
                          <Iframe
                            url={service.iframe.url}
                            width="100%"
                            height="100%"
                            allowFullScreen={true}
                          />
                        ) : service.iframe.image ? (
                          <Image
                            fluid={service.iframe.image.childImageSharp.fluid}
                          />
                        ) : null}
                      </Modal>

                      <div
                        css={
                          isEven
                            ? tw`md:text-right flex-grow p-6 md:p-0`
                            : tw`flex-grow p-6 md:p-0`
                        }
                      >
                        <div
                          css={tw`relative text-lg md:text-2xl font-light italic leading-normal mt-0 md:mt-4`}
                        >
                          <span
                            css={
                              isEven
                                ? tw`absolute hidden md:block top-half bg-white w-32 md:-right-20`
                                : tw`absolute hidden md:block top-half bg-white w-32 md:-left-20`
                            }
                            style={{
                              height: '1px',
                              translate: 'transformY(-50%)',
                            }}
                          />
                          <div
                            css={isEven ? tw`mt-0 md:mr-16` : tw`mt-0 md:ml-16`}
                          >
                            {service.title.map((line) => (
                              <p
                                key={line}
                                css={tw`inline-block md:block mr-2 last:mr-0 md:mr-0`}
                              >
                                {line}
                              </p>
                            ))}
                          </div>
                        </div>
                        {service.description.filter((line) => !!line).length ? (
                          <div
                            css={
                              isEven
                                ? tw`mt-6 md:mt-12 md:mr-24`
                                : tw`mt-6 md:mt-12 md:ml-24`
                            }
                          >
                            <div
                              css={
                                isEven
                                  ? tw`md:pr-6 md:py-3 pt-6 md:pt-0 border-t md:border-t-0 md:border-r border-white text-sm md:text-lg font-thin`
                                  : tw`md:pl-6 md:py-3 pt-6 md:pt-0 border-t md:border-t-0 md:border-l border-white text-sm md:text-lg font-thin`
                              }
                            >
                              {service.description.map((line) => (
                                <p key={line}>{line}</p>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </ContentWidth>
        </div>
      ))}
    </Layout>
  );
}

export const homeQuery = graphql`
  query HomeQuery {
    home: allHomeJson {
      edges {
        node {
          id
          top {
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          about {
            description
            link {
              title
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          works {
            list {
              title
              description
              iframe {
                url
                image {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              image {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            link {
              title
            }
          }
        }
      }
    }
  }
`;

export default Home;
